exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-entry-template-standard-jsx": () => import("./../../../src/templates/entry.template.standard.jsx" /* webpackChunkName: "component---src-templates-entry-template-standard-jsx" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "slice---src-slices-blank-index-tsx": () => import("./../../../src/@slices/blank/index.tsx" /* webpackChunkName: "slice---src-slices-blank-index-tsx" */),
  "slice---src-slices-modules-calendar-index-tsx": () => import("./../../../src/@slices/modules/calendar/index.tsx" /* webpackChunkName: "slice---src-slices-modules-calendar-index-tsx" */),
  "slice---src-slices-modules-cta-index-tsx": () => import("./../../../src/@slices/modules/cta/index.tsx" /* webpackChunkName: "slice---src-slices-modules-cta-index-tsx" */),
  "slice---src-slices-modules-faq-standard-index-tsx": () => import("./../../../src/@slices/modules/faqStandard/index.tsx" /* webpackChunkName: "slice---src-slices-modules-faq-standard-index-tsx" */),
  "slice---src-slices-modules-feed-list-item-default-index-tsx": () => import("./../../../src/@slices/modules/feedListItem-default/index.tsx" /* webpackChunkName: "slice---src-slices-modules-feed-list-item-default-index-tsx" */),
  "slice---src-slices-modules-full-image-index-tsx": () => import("./../../../src/@slices/modules/fullImage/index.tsx" /* webpackChunkName: "slice---src-slices-modules-full-image-index-tsx" */),
  "slice---src-slices-modules-hero-index-tsx": () => import("./../../../src/@slices/modules/hero/index.tsx" /* webpackChunkName: "slice---src-slices-modules-hero-index-tsx" */),
  "slice---src-slices-modules-iframes-index-tsx": () => import("./../../../src/@slices/modules/iframes/index.tsx" /* webpackChunkName: "slice---src-slices-modules-iframes-index-tsx" */),
  "slice---src-slices-modules-image-carousel-index-tsx": () => import("./../../../src/@slices/modules/imageCarousel/index.tsx" /* webpackChunkName: "slice---src-slices-modules-image-carousel-index-tsx" */),
  "slice---src-slices-modules-text-block-index-tsx": () => import("./../../../src/@slices/modules/textBlock/index.tsx" /* webpackChunkName: "slice---src-slices-modules-text-block-index-tsx" */)
}

